import React from "react";

import "./layout.scss";
import Helmet from "./helmet";
import Header from "./header";
import Footer from "./footer";

const Layout = ({ children }) => (
  <div
    onClick={() => {
      document.getElementsByClassName("dropdown-menu")[0].style.display =
        "none";
    }}
  >
    <Helmet />
    <Header />
    {children}
    <Footer />
  </div>
);

export default Layout;
