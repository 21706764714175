import React from "react";

const Footer = () => (
  <footer className="footer">
    <section className="hero">
      <div className="columns is-centered">
        <div className="column is-three-fifths">
          <h2 className="text-title" style={{ marginBottom: 50 }}>
            Contact
          </h2>

          <div className="columns">
            <div className="column is-one-third text-description has-text-weight-bold">
              사업 문의
            </div>
            <div className="column text-description">
              <a href="mailto:corp@bgpworks.com">corp@bgpworks.com</a>
            </div>
          </div>
          <div className="columns">
            <div className="column is-one-third text-description has-text-weight-bold">
              고객 문의
            </div>
            <div className="column text-description">
              <div style={{ whiteSpace: "pre", lineHeight: 2.5 }}>
                <span className="has-text-weight-bold">BOXHERO</span>
                <span>
                  {" "}
                  :{" "}
                  <a href="mailto:support+boxhero@bgpworks.com">
                    support+boxhero@bgpworks.com
                  </a>
                </span>
              </div>
              <div style={{ whiteSpace: "pre", lineHeight: 2.5 }}>
                <span className="has-text-weight-bold">BEEP</span>
                <span>
                  {" "}
                  :{" "}
                  <a href="mailto:support+beep@bgpworks.com">
                    support+beep@bgpworks.com
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column is-one-third text-description has-text-weight-bold">
              주소
            </div>
            <div
              style={{ whiteSpace: "pre" }}
              className="column text-description"
            >
              서울 강남구 역삼로175 TIPS타운 S3 7층
            </div>
          </div>
          <div className="columns">
            <div className="column is-one-third text-description has-text-weight-bold">
              사업자 번호
            </div>
            <div className="column text-description">832-86-00696</div>
          </div>
          <div className="columns">
            <div className="column text-description">
              © 2019 BGPworks. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </section>
  </footer>
);

export default Footer;
