import React from "react";

const MenuItem = ({ link, title }) => (
  <span
    className="navbar-item"
    style={{ padding: 25 }}
    onClick={() => scrollToClass(link)}
  >
    {title}
  </span>
);

const MenuItemDropdown = ({ link, title }) => (
  <div className="dropdown-item" onClick={() => scrollToClass(link)}>
    {title}
  </div>
);

const scrollTo = scrollY =>
  window.scrollTo({ top: scrollY, behavior: "smooth" });

const scrollToClass = cls => {
  const offsetTop = document.getElementsByClassName(cls)[0].offsetTop;
  const navHeight = document.getElementsByClassName("navbar")[0].clientHeight;

  scrollTo(offsetTop - navHeight);
};

export default class Navbar extends React.Component {
  state = {
    scrolled: false
  };

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll = () => {
    const scrolled = window.scrollY > 80;
    if (this.state.scrolled !== scrolled) {
      this.setState({
        scrolled: scrolled
      });
    }
  };

  render() {
    const logo = this.state.scrolled ? (
      <img
        className="logo-black"
        src={require("../images/ci_black.svg")}
        alt="BGPWORKS"
        style={{ maxHeight: "initial" }}
      />
    ) : (
      <img
        className="logo-white"
        src={require("../images/ci_white.svg")}
        alt="BGPWORKS"
        style={{ maxHeight: "initial" }}
      />
    );

    return (
      <div
        className={this.state.scrolled ? "hero-head head-white" : "hero-head"}
      >
        <nav
          className="navbar custom-navbar is-fixed-top repaint"
          role="navigation"
          aria-label="main navigation"
        >
          <div className="container">
            <div className="navbar-brand">
              <a
                href="#home"
                className="navbar-item"
                onClick={() => scrollTo(0)}
              >
                {logo}
              </a>
              <div
                role="button"
                style={{ padding: 16, textAlign: "center" }}
                className="navbar-burger has-dropdown burger"
                onClick={event => {
                  event.stopPropagation();
                  const dropdown = document.getElementsByClassName(
                    "dropdown-menu"
                  )[0];
                  if (dropdown.style.display === "block") {
                    dropdown.style.display = "none";
                  } else {
                    dropdown.style.display = "block";
                  }
                }}
              >
                <i
                  className={
                    this.state.scrolled
                      ? "mobile-menu black"
                      : "mobile-menu white"
                  }
                />
                <div
                  className="dropdown-menu"
                  style={{ right: 15, left: "inherit" }}
                  onClick={event => {
                    event.stopPropagation();
                  }}
                >
                  <div className="dropdown-content">
                    <MenuItemDropdown link="beep" title="BEEP" />
                    <MenuItemDropdown link="boxhero" title="BoxHero" />
                    <MenuItemDropdown link="footer" title="Contact" />
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-menu">
              <div className="navbar-end">
                <MenuItem link="beep" title="BEEP" />
                <MenuItem link="boxhero" title="BoxHero" />
                <MenuItem link="footer" title="Contact" />
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
