import React from "react";
import smoothscroll from "smoothscroll-polyfill";

import Layout from "../components/layout";



const urls = {
  beep_android:
    "https://play.google.com/store/apps/details?id=com.bgpworks.beep",
  beep_ios: "https://itunes.apple.com/app/id1242739153",
  beep_app: "https://www.beepscan.com/app",
  beep_web: "https://www.beepscan.com",

  boxhero_android:
    "https://play.google.com/store/apps/details?id=com.bgpworks.boxhero",
  boxhero_ios: "https://itunes.apple.com/app/id1325512157",
  boxhero_app: "https://www.boxhero.io/app",
  boxhero_web: "https://www.boxhero.io"
};

export default class IndexPage extends React.Component {
	componentDidMount() {
		smoothscroll.polyfill();
	}

	render() {
		return (
			<Layout>
			  <div className="intro-background">
				<section className="hero">
				  <div className="container-boxhero">
					<div className="intro-title-wrap">
					  <h1 className="intro-title">리테일,</h1>
					  <h1 className="intro-title">변화의</h1>
					  <h1 className="intro-title" style={{ display: "inline-block" }}>
						시작
					  </h1>
					  <i className="circle" />
					</div>
				  </div>
				</section>
			  </div>
			  <section className="hero">
				<div className="container-boxhero">
				  <div className="columns is-centered" style={{ marginTop: 120 }}>
					<div className="column is-three-fifths">
					  <h2 className="text-title">리테일 환경의 질적 개선을 선도</h2>
					  <div className="text-description" style={{ marginTop: 10 }}>
						IT 기술은 눈부시게 진화하고 있지만 소규모 사업자의 유통 환경은
						여전히 열악합니다. 우리는 로컬 비즈니스에서 실제로 필요로 하는
						시스템을 개발하고 서비스합니다. 문제점을 인식하고 우리의
						방법으로 불편함을 해소하겠습니다. 나아가 유통 환경의 질적 개선을
						선도하겠습니다.
					  </div>
					</div>
				  </div>
				  <div className="columns is-centered" style={{ marginTop: 120 }}>
					<div className="column is-three-fifths">
					  <h2 className="text-title">누구나, 언제 어니디서나, 간편하게</h2>
					  <div className="text-description" style={{ marginTop: 10 }}>
						우리는 전세계를 대상으로 다양한 로컬 비즈니스에서 사용할 수 있는
						서비스를 제공합니다. 누구나 쉽게 사용할 수 있으며, 이를 통해
						사용자들이 실질적인 이득을 얻을 수 있도록 도와드립니다. 우리의
						서비스는 Android, iOS, PC web등의 다양한 디바이스를 지원하여
						현장이나 사무실을 가리지 않고 간편하게 사용할 수 있습니다.
					  </div>
					</div>
				  </div>
				  <div
					className="columns is-centered"
					style={{ marginTop: 120, marginBottom: 120 }}
				  >
					<div className="column is-three-fifths">
					  <h2 className="text-title">효율적인 유통기한, 재고 관리</h2>
					  <div className="text-description" style={{ marginTop: 10 }}>
						유통기한과 재고 관리의 불편함을 해소하기 위해 모바일을 활용하여
						가장 쉬운 인터페이스를 개발하고, 팀 멤버들이 실시간으로 협업할
						수 있는 플랫폼을 제공합니다. 또한 바코드, QR코드, RFID등의
						기술을 적용하여 간편하고 효율 높은 업무 환경을 제시합니다.
					  </div>
					</div>
				  </div>
				</div>
			  </section>
			  <div className="service-background beep">
				<section className="hero">
				  <div className="container-boxhero">
					<div className="columns">
					  <div className="column">
						<div
						  className="text-description"
						  style={{ fontWeight: "bold", color: "#fff" }}
						>
						  서비스 소개- BEEP
						</div>
					  </div>
					</div>
					<div className="columns is-hidden-mobile" style={{ marginTop: 48 }}>
					  <div className="column" style={{ width: 350 }}>
						<div className="intro-title2-wrap" style={{ marginTop: 30 }}>
						  <h1 className="intro-title2">유통기한 관리</h1>
						  <h1 className="intro-title2">BEEP으로</h1>
						  <h1
							className="intro-title2"
							style={{ display: "inline-block" }}
						  >
							해법을 찾다
						  </h1>
						  <i
							className="circle"
							style={{ width: 20, height: 20, margin: "0 0 -6px 10px" }}
						  />
						</div>
						<div style={{ marginTop: 112 }}>
						  <div className="is-inline-block" style={{ float: "left" }}>
							<div
							  style={{
								boxShadow: "10px 10px 20px #22222233",
								width: 120,
								height: 120,
								display: "inline-block",
								borderRadius: 24
							  }}
							>
							  <img
								style={{ width: 120 }}
								src={require("../images/beep_icon.png")}
								alt="BEEP"
							  />
							</div>
						  </div>
						  <div
							className="is-inline-block"
							style={{ float: "left", marginLeft: 32 }}
						  >
							<div style={{ marginTop: 10 }}>
							  <div style={{ color: "#fff", fontSize: 12 }}>
								2018 애플 앱스토어
							  </div>
							  <div
								style={{
								  color: "#fff",
								  fontSize: 12,
								  fontWeight: "bold"
								}}
							  >
								오늘의 앱 선정
							  </div>
							</div>
							<div style={{ marginTop: 30 }}>
							  <div style={{ color: "#000", fontSize: 12 }}>
								2017 K-스타트업
							  </div>
							  <div
								style={{
								  color: "#000",
								  fontSize: 12,
								  fontWeight: "bold"
								}}
							  >
								창업선도대학 선정
							  </div>
							</div>
						  </div>
						  <div style={{ clear: "both", paddingTop: 40 }}>
							<a href={urls.beep_android}>
							  <img
								style={{ width: 114 }}
								src={require("../images/icon_googleplay.svg")}
								alt="Google Play Store"
							  />
							</a>
							<a href={urls.beep_ios}>
							  <img
								style={{ width: 114, marginLeft: 15 }}
								src={require("../images/icon_appstore.svg")}
								alt="Apple App Store"
							  />
							</a>
						  </div>
						</div>
					  </div>
					  <div className="column" style={{ clear: "both" }}>
						<div className="mobile-wrap">
						  <img src={require("../images/beep_mobile.png")} alt="Beep" />
						</div>
					  </div>
					</div>
					<div className="columns is-hidden-tablet">
					  <div className="column" style={{ width: 350 }}>
						<div className="intro-title2-wrap">
						  <h1 className="intro-title2">유통기한 관리</h1>
						  <h1 className="intro-title2">BEEP으로</h1>
						  <h1
							className="intro-title2"
							style={{ display: "inline-block" }}
						  >
							해법을 찾다
						  </h1>
						  <i className="circle2" />
						</div>
						<div className="mobile-wrap" style={{ marginTop: 42 }}>
						  <img
							src={require("../images/beep_mobile.png")}
							alt="Beep 스크린샷"
						  />
						</div>
						<div>
						  <div style={{ textAlign: "center", marginTop: 40 }}>
							<div
							  style={{
								boxShadow: "10px 10px 20px #22222233",
								width: 80,
								height: 80,
								display: "inline-block",
								borderRadius: 24
							  }}
							>
							  <img
								style={{ width: 120 }}
								src={require("../images/beep_icon.png")}
								alt="Beep"
							  />
							</div>
						  </div>
						  <div className="columns is-mobile" style={{ marginTop: 40 }}>
							<div className="column" style={{ textAlign: "center" }}>
							  <div style={{ color: "#000", fontSize: 12 }}>
								2018 애플 앱스토어
							  </div>
							  <div
								style={{
								  color: "#000",
								  fontSize: 12,
								  fontWeight: "bold"
								}}
							  >
								오늘의 앱 선정
							  </div>
							</div>
							<div className="column" style={{ textAlign: "center" }}>
							  <div style={{ color: "#000", fontSize: 12 }}>
								2017 K-스타트업
							  </div>
							  <div
								style={{
								  color: "#000",
								  fontSize: 12,
								  fontWeight: "bold"
								}}
							  >
								창업선도대학 선정
							  </div>
							</div>
						  </div>
						  <a href={urls.beep_app}>
							<div
							  className="button is-dark is-fullwidth"
							  style={{ height: 40, marginTop: 40, fontWeight: "bold" }}
							>
							  <i
								className="download"
								style={{ margin: "0 19px -2px 0px" }}
							  />
							  앱 다운로드
							</div>
						  </a>
						</div>
					  </div>
					</div>
				  </div>
				</section>
			  </div>
			  <section className="hero">
				<div className="container-boxhero">
				  <div
					className="columns is-centered"
					style={{ marginTop: 120, marginBottom: 120 }}
				  >
					<div className="column is-three-fifths">
					  <h2 className="text-title">BEEP- 유통기한 관리</h2>
					  <div className="text-description" style={{ marginTop: 10 }}>
						나날이 중요해지는 유통기한. 잘 관리되고 있나요? 상품은 많고
						유통기한도 모두 달라서 관리하기가 쉽지 않습니다. BEEP은 상품의
						바코드를 스캔하여 유통기한을 입력하고, 팀 멤버와 함께 어디서든
						관리가 가능한 모바일 앱입니다. 전세계 60여 개국의 마트, 약국,
						편의점등에서 사용되고 있습니다.
					  </div>
					  <a href={urls.beep_web}>
						<div className="button is-rounded button-custom">BEEP 소개</div>
					  </a>
					</div>
				  </div>
				</div>
			  </section>
			  <div className="service-background boxhero">
				<section className="hero">
				  <div className="container-boxhero">
					<div className="columns">
					  <div className="column">
						<div
						  className="text-description"
						  style={{ fontWeight: "bold", color: "#fff" }}
						>
						  서비스 소개- BOXHERO
						</div>
					  </div>
					</div>
					<div className="columns is-hidden-mobile" style={{ marginTop: 48 }}>
					  <div className="column" style={{ width: 350 }}>
						<div className="intro-title2-wrap" style={{ marginTop: 30 }}>
						  <h1 className="intro-title2">가장 쉬운</h1>
						  <h1 className="intro-title2">재고 관리-</h1>
						  <h1
							className="intro-title2"
							style={{ display: "inline-block" }}
						  >
							박스히어로
						  </h1>
						  <i
							className="circle"
							style={{ width: 20, height: 20, margin: "0 0 -6px 10px" }}
						  />
						</div>
						<div style={{ marginTop: 112 }}>
						  <div className="is-inline-block" style={{ float: "left" }}>
							<div
							  style={{
								boxShadow: "10px 10px 20px #22222233",
								width: 120,
								height: 120,
								display: "inline-block",
								borderRadius: 24
							  }}
							>
							  <img
								style={{ width: 120 }}
								src={require("../images/boxhero_icon.png")}
								alt="BoxHero"
							  />
							</div>
						  </div>
						  <div
							className="is-inline-block"
							style={{ float: "left", marginLeft: 32 }}
						  >
							<div style={{ marginTop: 10 }}>
							  <div style={{ color: "#fff", fontSize: 12 }}>
								2019 6월
							  </div>
							  <div
								style={{
								  color: "#fff",
								  fontSize: 12,
								  fontWeight: "bold"
								}}
							  >
								정식 출시
							  </div>
							</div>
						  </div>
						  <div style={{ clear: "both", paddingTop: 40 }}>
							<a href={urls.boxhero_android}>
							  <img
								style={{ width: 114 }}
								src={require("../images/icon_googleplay.svg")}
								alt="Google Play Store"
							  />
							</a>
							<a href={urls.boxhero_ios}>
							  <img
								style={{ width: 114, marginLeft: 15 }}
								src={require("../images/icon_appstore.svg")}
								alt="Apple App Store"
							  />
							</a>
						  </div>
						</div>
					  </div>
					  <div className="column" style={{ clear: "both" }}>
						<div className="mobile-wrap">
						  <img
							src={require("../images/boxhero_mobile.png")}
							alt="BoxHero Mobile"
						  />
						</div>
					  </div>
					</div>
					<div className="columns is-hidden-tablet">
					  <div className="column" style={{ width: 350 }}>
						<div className="intro-title2-wrap">
						  <h1 className="intro-title2">가장 쉬운</h1>
						  <h1 className="intro-title2">재고 관리-</h1>
						  <h1
							className="intro-title2"
							style={{ display: "inline-block" }}
						  >
							박스히어로
						  </h1>
						  <i className="circle2" />
						</div>
						<div className="mobile-wrap" style={{ marginTop: 42 }}>
						  <img
							src={require("../images/boxhero_mobile.png")}
							alt="BoxHero Mobile"
						  />
						</div>
						<div>
						  <div style={{ textAlign: "center", marginTop: 40 }}>
							<div
							  style={{
								boxShadow: "10px 10px 20px #22222233",
								width: 80,
								height: 80,
								display: "inline-block",
								borderRadius: 24
							  }}
							>
							  <img
								style={{ width: 120 }}
								src={require("../images/boxhero_icon.png")}
								alt="BoxHero"
							  />
							</div>
						  </div>
						  <div className="columns is-mobile" style={{ marginTop: 40 }}>
							<div className="column" style={{ textAlign: "center" }}>
							  <div style={{ color: "#000", fontSize: 12 }}>
								2019 6월
							  </div>
							  <div
								style={{
								  color: "#000",
								  fontSize: 12,
								  fontWeight: "bold"
								}}
							  >
								정식 출시
							  </div>
							</div>
						  </div>
						  <a href={urls.boxhero_app}>
							<div
							  className="button is-dark is-fullwidth"
							  style={{ height: 40, marginTop: 40, fontWeight: "bold" }}
							>
							  <i
								className="download"
								style={{ margin: "0 19px -2px 0px" }}
							  />
							  앱 다운로드
							</div>
						  </a>
						</div>
					  </div>
					</div>
				  </div>
				</section>
			  </div>
			  <section className="hero">
				<div className="container-boxhero">
				  <div className="columns is-centered" style={{ marginTop: 120 }}>
					<div className="column is-three-fifths">
					  <h2 className="text-title">BOXHERO- 가장 쉬운 재고 관리</h2>
					  <div className="text-description" style={{ marginTop: 10 }}>
						박스히어로는 심플하지만 확장 가능한 기능을 제공하여 업종 구분
						없이 손쉽게 재고를 제대로 관리할 수 있게 도와줍니다. 모바일로
						바코드와 QR코드를 스캔하여 제품의 검색, 입/출고, 재고 변동
						내역과 유통기한별 재고 수량도 쉽게 파악할 수 있습니다. 더불어
						모든 기능은 팀 멤버들과 함께 사용할 수 있습니다.
					  </div>
					  <a href={urls.boxhero_web}>
						<div className="button is-rounded button-custom">
						  박스히어로 소개
						</div>
					  </a>
					</div>
				  </div>
				  <div
					className="columns is-centered"
					style={{ marginTop: 120, marginBottom: 120 }}
				  >
					<div className="column is-three-fifths">
					  <h2 className="text-title">NEXT IS.. </h2>
					  <div className="text-description" style={{ marginTop: 10 }}>
						박스히어로는 진화중입니다. 딥러닝(AI)을 통한 바코드 인식과 문자
						인식률 향상을 위한 지속적인 연구와 데이터를 수집중이며, RFID
						태그를 활용한 재고 조사 기능를 연구 개발하고 있습니다. 또한
						축척된 데이터를 기반으로 적정재고를 알려 주는 재고 알림 AI를
						구현하기 위해 노력하고 있습니다.
					  </div>
					</div>
				  </div>
				</div>
			  </section>
			  <div className="service-background hr" />
			  <section className="hero">
				<div className="container-boxhero">
				  <div className="columns is-centered" style={{ marginBottom: 100 }}>
					<div className="column is-three-fifths">
					  <h2 className="text-title">Created by</h2>
					  <div className="text-description" style={{ marginTop: 10 }}>
						우리는 IT 기술로 조금 더 나은 세상을 만들기 위해 모였고, 그
						마음으로 제품을 만듭니다. 다방, 삼성, 뿌까 레스토랑 등 국내 유수
						IT기업 출신 인재들이 모여 리테일 환경 진화를 위한 문제를
						찾아내고, 해결 방안을 연구합니다.
					  </div>
					</div>
				  </div>
				</div>
			  </section>
			  <section className="hero">
				<div className="columns is-centered">
				  <div className="column is-half">
					<div className="columns">
					  <div className="column" style={{ marginBottom: 70 }}>
						<img src={require("../images/CEO.png")} alt="문희홍" />
						<div className="text-description" style={{ marginTop: 30 }}>
						  <p style={{ marginBottom: 5, fontWeight: "bold" }}>
							문희홍, CEO
						  </p>
						  <p>• (前) 다방(부동산 플랫폼) CEO/CTO</p>
						  <p>• 맙크리트(뿌까레스토랑) 공동 창업</p>
						</div>
					  </div>
					  <div className="column" style={{ marginBottom: 70 }}>
						<img src={require("../images/CPO.png")} alt="임상철" />
						<div className="text-description" style={{ marginTop: 30 }}>
						  <p style={{ marginBottom: 5, fontWeight: "bold" }}>
							임상철, CPO
						  </p>
						  <p>• (前) 다방(부동산 플랫폼) 개발실 총괄</p>
						  <p>• 맙크리트(뿌까레스토랑) CTO</p>
						</div>
					  </div>
					</div>
					<div className="columns">
					  <div className="column" style={{ marginBottom: 70 }}>
						<img src={require("../images/CTO.png")} alt="서정봉" />
						<div className="text-description" style={{ marginTop: 30 }}>
						  <p style={{ marginBottom: 5, fontWeight: "bold" }}>
							서정봉, CTO
						  </p>
						  <p>• (前) 베이글코드(소셜카지노) 서버팀 팀장</p>
						  <p>• 삼성전자 무선사업부</p>
						</div>
					  </div>
					  <div className="column" style={{ marginBottom: 70 }}>
						<img src={require("../images/CDO.png")} alt="이건우" />
						<div className="text-description" style={{ marginTop: 30 }}>
						  <p style={{ marginBottom: 5, fontWeight: "bold" }}>
							이건우, CDO
						  </p>
						  <p>• (前) 다방(부동산 플랫폼) 디자인 총괄</p>
						  <p>• 사이버메드(의료 소프트웨어) 디자인 팀장</p>
						</div>
					  </div>
					</div>
				  </div>
				</div>
			  </section>
			</Layout>
		  );
	}
}
